window.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    overrideBootstrapDropdown();
  }, 10);

  const wpcf7Forms = document.querySelectorAll(".wpcf7-form");
  if (wpcf7Forms) {
    wpcf7Forms.forEach((form) => {
      changeLabelPosition(form);
    });
  }
});

const overrideBootstrapDropdown = () => {
  const dropdownTogglerLinks = document.querySelectorAll(
    "header .nav-link.dropdown-toggle"
  );

  dropdownTogglerLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      window.location.href = link.getAttribute("href");
    });
  });
};

const changeLabelPosition = (wpcf7Form) => {
  const wpcf7FormLabels = wpcf7Form.querySelectorAll("label");
  if (wpcf7FormLabels) {
    wpcf7FormLabels.forEach((label) => {
      const parent = label.parentNode;
      const inputContainer = parent.querySelector(".wpcf7-form-control-wrap");
      const input = parent.querySelector("input");
      const select = parent.querySelector("select");
      if (input) {
        input.addEventListener("input", (e) => {
          if (e.target.value.length > 0) {
            label.classList.add("hide");
          } else {
            label.classList.remove("hide");
          }
        });
      }

      if (select) {
        select.addEventListener("change", (e) => {
          if (e.target.value.length > 0) {
            label.classList.add("hide");
          } else {
            label.classList.remove("hide");
          }
        });
      }

      if (inputContainer) {
        inputContainer.appendChild(label);
      }
    });
  }
};
